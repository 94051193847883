import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import _ from 'lodash';
import axios from 'axios';
import MaskedInput from 'react-maskedinput';
import '../index.css';
import { Formik } from 'formik';
import { niteSchema, dimexSchema, fisicaSchema, otherSchema } from './schema';
import AppLabel from '../../../AppLabel';

import {
  REGISTRO_API_KEY,
  REGISTRO_API_SECRET,
} from '../../../../config/AppConfig';

async function fetchData(numberId) {
  const config = {
    method: 'GET',
    url: `https://registro.banana.cr/api/cedula/nacional/${numberId}`,
    headers: {
      'api-key': REGISTRO_API_KEY,
      'api-secret': REGISTRO_API_SECRET,
    },
  };
  let firstName = '';
  let firstLastName = '';
  let secondLastName = '';
  let lastName = '';
  let country = '';
  try {
    const responseData = await axios(config);
    const { data } = responseData.data;
    firstName = _.startCase(_.toLower(data.first_name));
    firstLastName = _.startCase(_.toLower(data.primary_last_name));
    secondLastName = _.startCase(_.toLower(data.secondary_last_name));
    lastName = `${firstLastName} ${secondLastName}`;
    country = _.startCase(_.toLower(data.country));
  } catch (error) {
    // console.log(error);
  }
  return { firstName, lastName, country };
}

export default function Id({
  showDependent,
  type,
  onSubmit,
  defaultIdNumber,
  defaultFirstName,
  defaultLastName,
  labelForNameField,
  labelForLastnameField,
}) {
  const [dependent, setDependent] = useState(false);
  const [countryState, setCountryState] = useState('');
  const history = useHistory();
  let size = 9;
  // Define default schema for verification
  let schema;
  let mask;
  let placeholder;

  // Change schema, mask and placeholder according to the id type
  switch (type) {
    case 'DIMEX':
      size = 12;
      schema = dimexSchema;
      mask = '111111111111';
      placeholder = '#############';
      break;
    case 'NITE':
      size = 10;
      schema = niteSchema;
      mask = '1111111111';
      placeholder = '###########';
      break;
    case 'Cédula Física':
      schema = fisicaSchema;
      mask = '1-1111-1111';
      placeholder = '0-0000-0000';
      break;
    default:
      schema = otherSchema;
      mask =
        '*************************************************************************************************************';
      // mask = '';
      placeholder = '';
      break;
  }

  return (
    <Formik
      initialValues={{
        idNumber: defaultIdNumber,
        firstName: defaultFirstName,
        lastName: defaultLastName,
        type,
      }}
      validationSchema={schema}
      enableReinitialize
      validateOnChange
      validateOnMount
      // change 'redux state' from above when submitting
      onSubmit={({ idNumber, firstName, lastName }) => {
        onSubmit(idNumber, firstName, lastName, countryState, dependent);
      }}
    >
      {(formik) => (
        <>
          {type !== 'Selecciona un tipo' && (
          <Form className="resgistration-form center-form">
            {(type !== 'Sin documento / extranjero') && (
              <Form.Group controlId="formBasicNumber">
                <Form.Label>
                  Número
                  {' '}
                  {type}
                </Form.Label>
                <MaskedInput
                  mask={mask}
                  placeholder={placeholder}
                  size={size}
                  name="idNumber"
                  onBlur={formik.handleBlur}
                  value={formik.values.idNumber}
                  className={
                    formik.errors.idNumber && formik.touched.idNumber
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  onChange={(e) => {
                    // replace number with - and _ to a number only value
                    const value =
                      e.target.value.replace(/-/g, '').replace(/_/g, '') || '';
                    if (value.length === 9 && type === 'Cédula Física') {
                      fetchData(value)
                        .then(({ firstName, lastName, country }) => {
                          formik.handleChange('firstName')(firstName);
                          formik.handleChange('lastName')(lastName);
                          setCountryState(country);
                        })
                        .catch(() => {
                          // do nothing
                        });
                    }
                    formik.setFieldValue('idNumber', value);
                  }}
                />
                {formik.touched.idNumber && formik.errors.idNumber ? (
                  <span className="error-text">{formik.errors.idNumber}</span>
                ) : null}
              </Form.Group>
            )}
            <Form.Group controlId="formBasicNumber">
              <Form.Label>{!labelForNameField ? "Nombre del titular" : labelForNameField}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="firstName"
                value={formik.values.firstName}
                className={
                  formik.errors.firstName && formik.touched.idNumber
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <span className="error-text">{formik.errors.firstName}</span>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicNumber">
              <Form.Label>{!labelForLastnameField ? "Apellidos del titular" : labelForLastnameField}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Apellido"
                name="lastName"
                value={formik.values.lastName}
                className={
                  formik.errors.lastName && formik.touched.lastName
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <span className="error-text">{formik.errors.lastName}</span>
              )}
            </Form.Group>
            {showDependent && (
              <>
                <div className="d-flex align-items-center">
                  <Checkbox
                    color="primary"
                    name="dependent"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    value={dependent}
                    onChange={() => {
                      setDependent(!dependent);
                    }}
                  />
                  <AppLabel
                    className="owner-contact"
                    label="Utilizar datos de contacto del titular" />
                </div>
                <AppLabel
                  className="hint-text-bottom owner-contact"
                  label="Marcá esta casilla en caso de que el adicional sea menor de edad o adulto mayor."
                />
              </>
            )}
          </Form>
          )}
          {/* If 'labelForNameField' has a value, it means that the user is adding a beneficiary,
          because this is a custom label set when this action occurs. */}
          <div
            className={
              `d-flex align-items-center btn-wrap 
              ${labelForNameField 
                ? 'justify-content-between' 
                : 'justify-content-end'
              }`
            }>
            {labelForNameField && (
            <Button
              variant="link"
              className="btn-theme-outline mb-2"
              onClick={() => {
                history.push('/plan/3');
              }}
              disabled={false}
            >
              Atrás
            </Button>
            )}
            <Button
              variant="link"
              className="btn-theme mb-2"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid)}
            >
              Continuar
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
}
