const getDecomposedName = (fullName) => {
  const names = fullName.trim().split(' ');
  const spaces = names.length - 1;
  if (fullName === '') {
    return {
      firstName: '',
      initials: '',
      lastName: '',
    };
  }
  if (spaces === 0) {
    return {
      firstName: names[0],
      initials: names[0][0],
      lastName: undefined,
    };
  } if (spaces === 1) {
    return {
      firstName: names[0],
      lastName: names[1],
      initials: names[0][0] + names[1][0],
    };
  } if (spaces === 2) {
    return {
      firstName: `${names[0]} ${names[1]}`,
      lastName: names[2],
      initials: names[0][0] + names[1][0],
    };
  } if (spaces === 3) {
    return {
      firstName: `${names[0]} ${names[1]}`,
      lastName: `${names[2]} ${names[3]}`,
      initials: names[0][0] + names[1][0],
    };
  }
  return {
    firstName: fullName,
    initials: fullName[0],
  };
};
export default getDecomposedName;
