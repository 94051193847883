/* eslint-disable*/
import { SET_PROFILE } from '../actions/types';
import { getDecomposedName, getFullName } from '../../utils';

export const initialState = {
  name: '',
  initials: '',
  email: '',
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      const name = getFullName(
        action.payload.first_name,
        action.payload.last_name
      );
      const initials = getDecomposedName(name).initials;
      return { ...state, name, initials, email: action.payload.email };
    default:
      return state;
  }
};

export default profileReducer;
