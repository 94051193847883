import React from 'react';
import { Navbar } from 'react-bootstrap';
import brandlogo from '../../assets/img/logo.svg';
import './index.css';

const Header = () => (
  <Navbar className="navbar-header">
    <div className="container-fluid">
      <Navbar.Brand href="/">
        <img className="logo-header mx-auto d-block" src={brandlogo} alt="" />
      </Navbar.Brand>
    </div>
  </Navbar>
);

export default Header;
