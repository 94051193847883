/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { register as registerAction } from '../../../../../state/actions/register';

import addIcon from '../../../../../assets/img/add.svg';
import user from '../../../../../assets/img/perfil.svg';

const Step3Component = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const beneficiaries = useSelector((state) => state.register.beneficiaries);

  useEffect(() => {
    dispatch(registerAction({ beneficiariesCurrentStep: 2 }));
  }, []);

  function handleNext() {
    history.push('/plan/4');
  }

  return (
    <>
      <Form className="resgistration-form">
        <div className="form-header">
          <h2 className="form-title">
            <span>Adicionales agregados</span>
          </h2>
        </div>
        {beneficiaries.length > 0
          ? beneficiaries.map((listUser) => (
            <div className="user-list ">
              {' '}
              <img src={user} alt="user" />
              {`${listUser.first_name} ${listUser.last_name}`}
            </div>
          ))
          : null}
        <div className="mt-4 cursor-pointer user-list ">
          <span
            onClick={() => {
              history.push('/beneficiario/1');
            }}
          >
            <img src={addIcon} alt="add" />
            Agregar adicional
          </span>
        </div>
      </Form>
      <div className="d-flex align-items-center justify-content-between btn-wrap">
        <Button
          variant="link"
          className="btn-theme-outline mb-2"
          onClick={() => {
            history.push('/beneficiario/1');
          }}
          disabled={false}
        >
          Atrás
        </Button>
        <Button
          variant="link"
          className="btn-theme mb-2"
          onClick={handleNext}
        >
          Continuar
        </Button>
      </div>
    </>
  );
};

export default Step3Component;
