import { gql } from '@apollo/client';

const CANCEL_EXISTING_SUBSCRIPTION = gql`
  mutation CANCEL_EXISTING_SUBSCRIPTION($customer_id: String!) {
    cancelExistingSubscription(customer_id: $customer_id) {
      id
    }
  }
`;
export default CANCEL_EXISTING_SUBSCRIPTION;
