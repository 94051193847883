import { gql } from '@apollo/client';

const LOGIN = gql`
  query LOGIN($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        first_name
        last_name
        customer_type
        email
        dni
        mobile
        status
        subscription {
          id
          created
          trial_days
          plan {
            id
            name
            months
            price
            tax_id
            trial_period
          }
          addons {
            id
            name
            description
            plans {
              id
              name
              months
              price
              tax_id
            }
            price
          }
        }
        beneficiaries {
          id
          first_name
          last_name
          customer_type
          email
          dni
          mobile
          status
          subscription {
            id
            created
            plan {
              id
              name
              months
              price
              tax_id
            }
            addons {
              id
              name
              description
              plans {
                id
                name
                months
                price
                tax_id
              }
              price
            }
          }
          payments {
            date
          }
        }
        payments {
          date
        }
      }
      tokens {
        token
        refresh_token
      }
      permissions_roles
    }
  }
`;
export default LOGIN;
