const CANCELATION = {
  label: 'Cancelaciones',
  name: 'cancelation',
};

const CLIENTS = {
  label: 'Clientes',
  name: 'clients',
};

const PAYMENT = {
  label: 'Método de Pago',
  name: 'payment',
};

const PROFILE = {
  label: 'Perfil',
  name: 'profile',
};

const SUBSCRIPTION = {
  label: 'Suscripción',
  name: 'plans',
};

export {
  CANCELATION,
  CLIENTS,
  PAYMENT,
  PROFILE,
  SUBSCRIPTION,
};
