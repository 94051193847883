// email variables
export const Email_Id = process.env.REACT_APP_EMAIL_ID;
export const Email_Password = process.env.REACT_APP_EMAIL_PASSWORD;
export const Email_Template = process.env.REACT_APP_EMAIL_TEMPLATE;
export const Email_Serviceid = process.env.REACT_APP_EMAIL_SERVICE_ID;
export const REGISTRO_API_KEY = 't8XxyyT8azTysfrg5hMy5g9K';
export const REGISTRO_API_SECRET = 'EHsYax48QcXG5bTCKz9kYToyJGjjea';
export const CARD_API_KEY = 'feU5neXF7QAkLzkdi4MJhdkE/i7bFlv3EJEP2rfp4r8';
const REACT_APP_PROD_API = process.env.REACT_APP_PROD_API || 'https://salud-360-server-wmktg.ondigitalocean.app'//'http://localhost:5005'//'https://salud-360-server-wmktg.ondigitalocean.app';//'https://salud.banana.cr';//'https://salud-360-server-wmktg.ondigitalocean.app';
const REACT_APP_DEV_API = process.env.REACT_APP_DEV_API || 'https://salud-360-server-wmktg.ondigitalocean.app'//'http://localhost:5005'//'https://salud-360-server-wmktg.ondigitalocean.app';//'https://salud.banana.cr';//'https://salud-360-server-wmktg.ondigitalocean.app';
//const REACT_APP_PROD_API = 'http://localhost:5005';

export const API_ENDPOINT = process.env.REACT_APP_BRANCH === 'dev' ? REACT_APP_DEV_API : REACT_APP_PROD_API;
