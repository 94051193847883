
function formatNumber(number) {
  const precision = 2;
  return Number.parseFloat(number).toFixed(precision);
}


/**
 * Calculates the price breakdown for checkout
 *
 * si viene all_recurring o all_onetime hay que verificar el tipo de beneficiary_cost
 * si es select se aplica solo si el addon o beneficiario esta en la lista
 *
 * fact: le hace el descuento por separado a cada tipo de complemento, plan o descuento
 * fact: se coje el porcentaje del iva del plan no de los complementos o adicionales
 * @param {plan} currentPlan the plan coming from the backend
 * @param {coupon} coupon the coupon from the backend
 * @param {int} quantityBeneficiaries the quantity of beneficiaries
 * @param {Array<addons>} addons an array of addons
 */
export default function calculatePriceBreakdown(
  currentPlan,
  coupon = false,
  quantityBeneficiaries,
  addons = false
) {
  // possible responses coming from the backend
  const applyToPlansAll = 'all';
  const applyToPlansNone = 'none';
  const applyToPlansSelect = 'select';

  const applyToAddonsAllRecurring = 'all_recurring';
  const applyToAddonsAllOneTime = 'all_onetime';
  const applyToAddonsNone = 'none';
  const applyToAddonsSelect = 'select';

  const beneficiaryTypeOneTime = 'one_time';
  const beneficiaryTypeRecurring = 'recurring';

  let planDiscount = 0;
  let beneficiariesDiscount = 0;
  let addonsDiscount = 0;
  let discount = 0;

  let planPrice = 0;
  let planSubtotal = 0;
  let ivaPlan = 0;

  // single beneficiary price
  let beneficiaryPrice = 0;
  // beneficiaries price
  let beneficiariesPrice = 0; // Without discount
  let beneficiariesSubtotal = 0;
  let ivaBeneficiaries = 0;

  let addonsPrice = 0;
  let addonsSubtotal = 0;
  let ivaAddons = 0;

  let subtotal = 0;
  let ivaTotal = 0;
  let total = 0;

  if (currentPlan.id) {
    const plan = currentPlan;
    const currentCoupon = coupon;

    planPrice = plan.price;
    planSubtotal = plan.price;
    beneficiaryPrice = plan.beneficiary_cost.price;
    beneficiariesSubtotal = 0;

    // discount the plan if the coupon apply_to_plans has 'all' or 'select'
    // and if 'select' check to see if the coupon applies
    // to the current plan
    const shouldDiscountPlan =
      currentCoupon?.apply_to_plans === applyToPlansAll ||
      (currentCoupon?.apply_to_plans === applyToPlansSelect &&
        currentCoupon?.plans?.findIndex((couponPlan) => couponPlan.id === plan.id) !== -1);

    planDiscount = 0;
    // discount the plan
    if (shouldDiscountPlan) {
      // calculate a discount for the plan
      if (currentCoupon?.discount_value) {
        // discount only the plan price
        planDiscount = parseInt(currentCoupon.discount_value);
      } else if (currentCoupon?.percentage_value) {
        planDiscount = plan.price * (currentCoupon.percentage_value / 100);
      }
      // discount only the plan price
      planSubtotal = plan.price - planDiscount;
      // if negative leave at 0
      if (planSubtotal < 0) {
        planSubtotal = 0;
        // if the discount is higher than the price, then the max discount is the price
        planDiscount = plan.price;
      }
    }

    // multiply the price by the quantity of beneficiaries if there is more than 0
    if (quantityBeneficiaries > 0) {
      beneficiariesSubtotal = beneficiaryPrice * quantityBeneficiaries;
      beneficiariesPrice = beneficiaryPrice * quantityBeneficiaries;
    }

    beneficiariesDiscount = 0;

    let shouldDiscountBeneficiary = false;
    if (
      currentCoupon?.apply_to_addons === applyToAddonsAllRecurring &&
      currentPlan.beneficiary_cost.type === beneficiaryTypeRecurring
    ) {
      shouldDiscountBeneficiary = true;
    }
    if (
      currentCoupon?.apply_to_addons === applyToAddonsAllOneTime &&
      currentPlan.beneficiary_cost.type === beneficiaryTypeOneTime
    ) {
      shouldDiscountBeneficiary = true;
    }

    // if the coupon applies to beneficiary,
    // discount the value to the beneficiaries subtotal
    if (currentCoupon?.beneficiary || shouldDiscountBeneficiary) {
      if (currentCoupon.discount_value) {
        beneficiariesDiscount = parseInt(currentCoupon.discount_value);
      } else if (currentCoupon.percentage_value) {
        beneficiariesDiscount = beneficiariesSubtotal * (currentCoupon.percentage_value / 100);
      }
      beneficiariesSubtotal = beneficiariesSubtotal - beneficiariesDiscount;
      if (beneficiariesSubtotal < 0) {
        beneficiariesSubtotal = 0;
        // if the discount is higher than the price, then the max discount is the price
        beneficiariesDiscount = beneficiariesPrice;
      }
    }

    if (addons) {
      // For each addon discount
      for (let addonIndex = 0; addonIndex < addons.length; addonIndex++) {
        const addon = addons[addonIndex];

        // Individual addon calculations -------------------------------------------
        let addonDiscount = 0;
        let addonPrice = addon.price;
        let addonSubtotal = addon.price;
        let addonIva = addonSubtotal * (plan.iva / 100);

        let shouldDiscountAddon = false;
        if (
          currentCoupon?.apply_to_addons === applyToAddonsAllRecurring &&
          addon.type === beneficiaryTypeRecurring
        ) {
          shouldDiscountAddon = true;
        }
        if (
          currentCoupon?.apply_to_addons === applyToAddonsAllOneTime &&
          addon.type === beneficiaryTypeOneTime
        ) {
          shouldDiscountAddon = true;
        }

        if (shouldDiscountAddon) {
          if (currentCoupon.discount_value) {
            addonDiscount = parseInt(currentCoupon.discount_value);
          } else if (currentCoupon.percentage_value) {
            addonDiscount = addonSubtotal * (currentCoupon.percentage_value / 100);
          }
          addonSubtotal = addonSubtotal - addonDiscount;
          if (addonSubtotal < 0) {
            addonSubtotal = 0;
            // if the discount is higher than the price, then the max discount is the price
            addonDiscount = addonPrice;
          }
        }
        // End of Individual addon calculations -------------------------------------------

        addonsPrice += addonPrice;
        addonsSubtotal += addonSubtotal;
        // we take the plan iva (thats how the zoho backend does it)
        ivaAddons += addonIva;
        addonsDiscount += addonDiscount;
      }
    }

    discount = beneficiariesDiscount + planDiscount + addonsDiscount;
    ivaPlan = planSubtotal * (plan.iva / 100);
    ivaBeneficiaries = beneficiariesSubtotal * (plan.beneficiary_cost.iva / 100);
    subtotal = beneficiariesSubtotal + planSubtotal + addonsSubtotal;
    ivaTotal = ivaPlan + ivaBeneficiaries + ivaAddons;
    total = subtotal + ivaTotal;
  }
  discount = formatNumber(discount);
  planSubtotal = formatNumber(planSubtotal);
  addonsPrice = formatNumber(addonsPrice);
  beneficiariesSubtotal = formatNumber(beneficiariesSubtotal);
  ivaPlan = formatNumber(ivaPlan);
  ivaBeneficiaries = formatNumber(ivaBeneficiaries);
  total = formatNumber(total);
  subtotal = formatNumber(subtotal);
  ivaTotal = formatNumber(ivaTotal);
  beneficiariesPrice = formatNumber(beneficiariesPrice);
  beneficiaryPrice = formatNumber(beneficiaryPrice);
  addonsSubtotal = formatNumber(addonsSubtotal);

  return {
    subtotal,
    discount,
    ivaTotal,
    total,
    planPrice,
    planSubtotal,
    addonsPrice,
    addonsSubtotal,
    beneficiariesPrice,
    beneficiariesSubtotal,
    beneficiaryPrice,
  };
}
