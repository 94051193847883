/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Alert } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import * as classnames from 'classnames';
import '../index.css';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import PriceBox from '../PriceBox';
import infoicon from '../../../../assets/img/info-icon.svg';
import { register as registerAction } from '../../../../state/actions/register';
import Loader from '../../../../Common/Loader/Loader';
import { Queries } from '../../../../Queries';
import schema from './schema';
import TagManager from 'react-gtm-module';
import { gtmNames, mastercardPlan } from '../../../../constants';
import * as couponValidations from '../../../../utils/couponValidations';
import card from '../../../../assets/img/card.svg';
import { 
  getCreditCardType, 
  isValidCardExpirityDate, 
  splitDateIntoYearAndMonth 
} from '../../../../utils/creditCardValidations';

const Step4 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [promo, setPromo] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [couponSuccess, setCouponSuccess] = useState(false);
  const [queryError, setQueryError] = useState('');
  const [loading, setLoading] = useState(false);
  const [getCoupon, { data, error: couponError }] = useLazyQuery(Queries.COUPON);
  const registerData = useSelector((state) => state.register);
  const currentPlan = useSelector((state) => state.register.currentPlan);
  const [ errorMessage, setErrorMessage ] = useState("");
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cardBrandLogo, setCardBrandLogo] = useState('');
  const [error, setError] = useState({});

  useEffect(() => {
    if (registerData.couponId) {
      setPromo(true);
    }
  });

  useEffect(() => {
    if (registerData.couponId || registerData.coupon?.id) {
      setShowPromo(true);
      setCouponSuccess(true);
    }
  }, [registerData]);

  useEffect(() => {
    if (couponError) {
      dispatch(registerAction({ coupon: couponValidations.cleanCoupon }));
      setErrorMessage(couponValidations.defaultErrorMessage);
    } else {
      setErrorMessage("");
    }
  }, [couponError]);

  useEffect(() => {
    if (data?.coupon) {
      let validatedCoupon = 
        couponValidations.validateCoupon(data.coupon, currentPlan);
      let newState = { coupon: { ...validatedCoupon.coupon } };
      if (validatedCoupon.error) {
        newState = { ...newState, couponId: '' };
        setErrorMessage(validatedCoupon.message);
      }
      dispatch(registerAction({ ...newState }));
    } else {
      setErrorMessage("");
    }
  }, [data]);

  useEffect(() => {
    dispatch(registerAction({ currentStep: 3 }));
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (cardNumber) {
        const { brand, logo, errorMessage } = getCreditCardType(cardNumber);
        if(brand) setCardBrandLogo(brand);
        if(logo) {
          setCardBrandLogo(logo);
        } else {
          setCardBrandLogo(card);
        }
        setError(error => 
          error = {...error, cardNumber: errorMessage}
        );        
      }
      if (expiryDate) {
        const { month, year } = splitDateIntoYearAndMonth(expiryDate);
        const { expirityDateError } = isValidCardExpirityDate(year, month);
        setError(error => 
          error = {...error, expiryDate: expirityDateError}
        );
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [cardNumber, expiryDate]);

  function onBack() {
    if (registerData.beneficiaries.length > 0) {
      history.push('/beneficiario/3');
    } else {
      history.push('/plan/3');
    }
  }

  const makeCardPaymentHeaders = (name, number, cvc, expirationMonth, expirationYear) => ({
    method: 'POST',
    mode:'no-cors',
    url: 'https://monkfish-app-8it7u.ondigitalocean.app/practice/v2/payment/gp/token',
    headers: { 
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    data: {
      name,
      number,
      cvc,
      expirationMonth,
      expirationYear,
      nickname: name,
    },
  });

  if (loading) return <Loader />;
  return (
    <Formik
      initialValues={{
        name: `${registerData.firstName} ${registerData.lastName}`,
        cardNumber: '',
        expirationDate: '',
        cvv: '',
        promoCode: registerData.couponId,
      }}
      validationSchema={schema}
      validateOnChange
      // change 'redux state' from above when submitting
      onSubmit={({ name, cardNumber, expirationDate, cvv, promoCode }) => {
        const expMonth = parseInt(expirationDate.split('/')[0]);
        const expYear = parseInt(expirationDate.split('/')[1]);
        setLoading(true);
        axios(makeCardPaymentHeaders(name, cardNumber, cvv, expMonth, expYear))
          .then((response) => {
            if (response.data.token === undefined) {
              throw 'Tarjeta no válida';
            }
            let currentPlanName = currentPlan.name.toLowerCase();
            if (currentPlanName.includes(mastercardPlan.brand) && response.data.card.brand !== mastercardPlan.brand) {
              let brandFromResponse = response.data.card.brand;
              // Adds capital letter
              let brand = brandFromResponse.charAt(0).toUpperCase() + brandFromResponse.slice(1);
              throw `El plan Salud 360 Mastercard no está disponible para tarjetas ${brand}`;
            }
            dispatch(
              registerAction({
                cardInfo: {
                  cardToken: response.data.token,
                  cardNumber: response.data.card.lastDigits,
                  expMonth,
                  expYear,
                  cardHolder: name,
                  couponId: promoCode,
                  cardBrand: response.data.card.brand,
                },
              })
            );
            // Triggers GTM
            TagManager.dataLayer({
              dataLayer: {
                event: gtmNames.addPaymentInfo,
              },
            });
            history.push('/plan/5');
            setLoading(false);
          })
          .catch((err) => {
            setQueryError(err);
            setLoading(false);
          });
      }}
    >
      {(formik) => (
        <>
          <div className="d-flex plan-container">
            <Form className="resgistration-form">
              <div className="form-header">
                <h2 className="form-title">
                  <span>Método de Pago</span>
                </h2>
              </div>

              <Form.Group controlId="formBasicNumber">
                <Form.Label>Nombre completo</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Nombre Apellido"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name ? (
                  <span className="error-text">{formik.errors.name}</span>
                ) : null}
              </Form.Group>

              <Form.Group>
                <Form.Label>Número de tarjeta</Form.Label>
                <div className="card-number">
                  <MaskedInput
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    className={classnames('form-control', {
                      'is-invalid': formik.touched.cardNumber && formik.errors.cardNumber,
                    })}
                    placeholder="Introducir número de tarjeta"
                    value={formik.values.cardNumber}
                    name="cardNumber"
                    onChange={(e) => {
                      const value =
                        e.target.value
                          .replace(/-/g, '')
                          .replace(/_/g, '')
                          .replace(/ /g, '') || '';
                      formik.setFieldValue('cardNumber', value);
                      setCardNumber(e.target.value)
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <img
                    className="card-brand"
                    src={cardBrandLogo || card}
                    alt="card logo"
                  />
                </div>
                {error.cardNumber ? (
                  <span className="error-text">{error.cardNumber}</span>
                ) : formik.touched.cardNumber && formik.errors.cardNumber && (
                  <span className="error-text">{formik.errors.cardNumber}</span>
                )}
              </Form.Group>

              <Row>
                <Col md="6">
                  <Form.Group controlId="formBasicNumber">
                    <Form.Label> Fecha de expiración </Form.Label>
                    <MaskedInput
                      mask={[/[0-9]/, /\d/, '/', /\d/, /\d/]}
                      className={classnames('form-control', {
                        'is-invalid': formik.errors.expirationDate,
                      })}
                      placeholder="mm/aa"
                      id="expirationDate"
                      name="expirationDate"
                      value={formik.values.expirationDate}
                      onChange={(e) => {
                        // replace number with / and _ to a number only value
                        const value =
                          e.target.value.replace(/-/g, '').replace(/_/g, '').replace(/ /g, '') || '';
                        formik.setFieldValue('expirationDate', value);
                        setExpiryDate(value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {error.expiryDate ? (
                      <span className="error-text">{error.expiryDate}</span>
                    ) : formik.touched.expirationDate && formik.errors.expirationDate && (
                      <span className="error-text">{formik.errors.expirationDate}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label htmlFor="cvv">
                      CVV
                      <span className="info-img">
                        <img src={infoicon} alt="info icon" />
                      </span>
                    </Form.Label>
                    <div className="input-block">
                      <MaskedInput
                        mask={[/[0-9]/, /\d/, /\d/]}
                        className={classnames('form-control', {
                          'is-invalid': formik.touched.cvv && formik.errors.cvv,
                        })}
                        placeholder="Introducir CVV"
                        id="cvv"
                        name="cvv"
                        value={formik.values.cvv}
                        onChange={(e) => {
                          // replace number with / and _ to a number only value
                          const value =
                            e.target.value.replace(/\\/g, '').replace(/_/g, '').replace(/ /g, '') ||
                            '';
                          formik.setFieldValue('cvv', value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.cvv && formik.errors.cvv ? (
                        <span className="error-text">{formik.errors.cvv} </span>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>                
              </Row>
              <Row>
                {!promo
                  ? showPromo || registerData.coupon.id 
                    ? (
                      <Col md="12">
                        <Form.Group>
                          <Form.Label>Ingrese el código promocional</Form.Label>
                          <Form.Control
                            type="text"
                            value={ formik.values.promoCode || registerData.coupon.coupon_code }
                            name="promoCode"
                            onChange={(e) => {
                              const { value } = e.target;
                              formik.setFieldValue('promoCode', value);
                              getCoupon({ variables: { id: value } });
                            }}
                            onBlur={formik.handleBlur}
                            placeholder="Código"
                          />
                          {errorMessage !== "" ? <span className="error-text">{errorMessage}</span> : null}
                        </Form.Group>
                      </Col> )
                    : null
                  : null
                }
              </Row>
              {!promo 
                ? !showPromo
                  ? ( <div class="promo-code-div">
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                      <span className="theme-link" onClick={() => setShowPromo(true)}>
                        ¿Tenés un código promocional?
                      </span>
                    </div> )
                  : null
                : null
              }
            </Form>
            <div className="right-box stp4-box">
              <PriceBox />
              <Alert
                show={queryError}
                variant="danger"
                onClose={() => {
                  setQueryError('');
                }}
                dismissible
                className="custom-alert-box"
              >
                {queryError}
              </Alert>
              {(promo || registerData.coupon.id) && (
                <Alert
                show={couponSuccess}
                onClose={() => setCouponSuccess(false)}
                dismissible
                className="custom-alert-box custom-alert-box-success"
                >
                  ¡Se ha aplicado correctamente un código promocional a tu plan!
                </Alert>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between btn-wrap">
            <Button
              variant="link"
              className="btn-theme-outline mb-2"
              onClick={onBack}
              disabled={false}
            >
              Atrás
            </Button>
            <Button
              variant="link"
              className="btn-theme mb-2"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty) || error.cardNumber || error.expiryDate}
            >
              Continuar
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};
export default Step4;
