import { gql } from '@apollo/client';

const PLANS = gql`
  query PLANS {
    plans {
      id
      name
      price
      months
      iva
      total_price
      tax_id
      plan_type
      trial_period
      beneficiary_cost {
        id
        name
        description
        price
        iva
        total_price
        type
      }
      addons {
        description
        id
        name
        iva
        price
        total_price
      }
    }
  }
`;
export default PLANS;
