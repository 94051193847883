import { gql } from '@apollo/client';

// Page starts with 1
const CANCELATION_REQUESTS = gql`
  query CANCELATION_REQUESTS {
    cancelationRequests {
      id
      user {
        first_name
        last_name
        email
        id
        dni
      }
      user_database {
        display_name
        dni
        email_contact
      }
      created_at
      type
    }
  }
`;
export default CANCELATION_REQUESTS;
