import { gql } from '@apollo/client';

// Page starts with 1
const SEARCH = gql`
  query SEARCH($type: String!, $data: String!, $page: Int!) {
    search(type: $type, data: $data, page: $page) {
      id
      first_name
      last_name
      display_name
      customer_type
      email
      dni
      mobile
      status
      created_at
      subscription {
        status
        amount
        id
        created
        plan {
          plan_type
          id
          name
          months
          price
          total_price
          tax_id
        }
      }
      beneficiaries {
        id
        first_name
        last_name
        customer_type
        email
        dni
        mobile
        status
        subscription {
          status
          id
          created
          amount
          plan {
            total_price
            plan_type
            id
            name
            months
            price
            tax_id
          }
        }
      }
      payments {
        date
      }
    }
  }
`;
export default SEARCH;
