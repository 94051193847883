/* Available user actions. */

const CANCEL = 'CANCEL';
const EDIT = 'EDIT';
const MENU = 'MENU';
const VIEW = 'VIEW';

export {
  CANCEL,
  EDIT,
  MENU,
  VIEW,
};