/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

const firstNameError = 'Nombre tiene que tener menos de ${max} caracteres';
const lastNameError = 'Apellido tiene que tener menos de ${max} caracteres';
const requiredError = 'Requerido';

export const dimexSchema = Yup.object({
  idNumber: Yup.string()
    .min(12, 'Número de DIMEX tiene mínimo ${min} números')
    .max(12, 'Número de DIMEX tiene menos de ${min} números')
    .required(requiredError),
  firstName: Yup.string()
    .max(50, firstNameError)
    .required(requiredError),
  lastName: Yup.string()
    .max(50, lastNameError)
    .required(requiredError),
});

export const niteSchema = Yup.object({
  idNumber: Yup.string()
    .min(10, 'Número de NITE tiene mínimo ${min} números')
    .max(10, 'Número de NITE tiene menos de ${min} números')
    .required(requiredError),
  firstName: Yup.string()
    .max(50, firstNameError)
    .required(requiredError),
  lastName: Yup.string()
    .max(50, lastNameError)
    .required(requiredError),
});

export const fisicaSchema = Yup.object({
  idNumber: Yup.string()
    .min(9, 'Número de cédula tiene mínimo ${min} números')
    .max(9, 'Número de cédula tiene menos de ${min} números')
    .required('Requerido'),
  firstName: Yup.string()
    .max(50, firstNameError)
    .required(requiredError),
  lastName: Yup.string()
    .max(50, lastNameError)
    .required(requiredError),
});

export const otherSchema = Yup.object({
  firstName: Yup.string()
    .max(50, firstNameError)
    .required(requiredError),
  lastName: Yup.string()
    .max(50, lastNameError)
    .required(requiredError),
});
