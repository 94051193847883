import visa from "../assets/img/visa.svg";
import mastercard from "../assets/img/mastercard.svg";

const baseCardRegex =
  "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|" +
  "6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|" +
  "3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$";

const cardTypes = {
  visa: {
    regex: "^4[0-9]{12}(?:[0-9]{3})?$",
    logo: visa,
  },
  mastercard: {
    regex:
      "^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|" +
      "7[0-1][0-9]{13}|720[0-9]{12}))$",
    logo: mastercard,
  },
};

const errorTypes = {
  invalidCardNumber: "El número de tarjeta ingresado es incorrecto",
  notAllowedBrand: "Por el momento solamente aceptamos tarjetas Mastercard y Visa",
  expiredCard: "La tarjeta ha expirado",
  invalidExpirityMonth: "El mes ingresado es incorrecto",
  invalidExpirityYear: "El año ingresado es incorrecto",
};

const getCreditCardType = (cardNumber) => {
  let brand = "";
  let logo = "";
  let errorMessage = "";
  const parsedCardNumber = parseCardNumber(cardNumber);
  let isValidCard = new RegExp(baseCardRegex).test(parsedCardNumber);

  if (!isValidCard) {
    errorMessage = errorTypes.invalidCardNumber;
  } else {
    for (const type in cardTypes) {
      let regex = new RegExp(cardTypes[type].regex);

      if (regex.test(parsedCardNumber)) {
        return { brand: type, logo: cardTypes[type].logo, errorMessage: "" };
      } else {
        errorMessage = errorTypes.notAllowedBrand;
      }
    }
  }

  return { brand, logo, errorMessage };
};

const isValidCardExpirityDate = (year, month) => {
  const fullYear = "20" + year;
  const today = new Date().getTime();
  const expirityDate = new Date().setFullYear(fullYear, month);

  if (Number.isNaN(month) || month < 1 || month > 12) {
    return {
      isValid: false,
      expirityDateError: errorTypes.invalidExpirityMonth,
    };
  }

  if (Number.isNaN(year) || year.toString().length != 2 ) {
    return {
      isValid: false,
      expirityDateError: errorTypes.invalidExpirityYear,
    };
  }
  const isValid = today < expirityDate;
  let expirityDateError = "";
  if (!isValid) expirityDateError = errorTypes.expiredCard;

  return { isValid, expirityDateError };
};

const splitDateIntoYearAndMonth = (date) => {
  date = date.split("/");
  const month = date[0][0] == "0" ? parseInt(date[0][1]) : parseInt(date[0]);
  const year = parseInt(date[1]);

  return { year, month };
};

const parseCardNumber = (cardNumber) => {
  if (cardNumber && typeof cardNumber === "string") {
    return (
      cardNumber.replace(/-/g, "").replace(/_/g, "").replace(/ /g, "") || ""
    );
  }
};

export {
  getCreditCardType,
  isValidCardExpirityDate,
  splitDateIntoYearAndMonth,
};
