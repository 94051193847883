import { gql } from '@apollo/client';

const PUBLIC_SEARCH = gql`
  query PUBLIC_SEARCH($type: String!, $data: String!, $page: Int!) {
    search(type: $type, data: $data, page: $page) {
      id
      customer_type
      display_name
      subscription {
        status
        id
        created
        plan {
          plan_type
          id
        }
      }
    }
  }
`;
export default PUBLIC_SEARCH;
