import { gql } from '@apollo/client';

const ADD_ADDONS = gql`
  mutation ADD_ADDONS($addons_list: String!) {
    addAddons(addons_list: $addons_list) {
      id
      created
      plan {
        id
        name
        months
        price
        tax_id
      }
      addons {
        id
        name
        description
        plans {
          id
          name
          months
          price
          tax_id
        }
        price
      }
    }
  }
`;
export default ADD_ADDONS;
