import { combineReducers } from 'redux';
import authReducer from './auth';
import errorReducer from './error';
import registerReducer from './register';
import userReducer from './user';
import settingsReducer from './settings';
import subscriptionReducer from './subscription';
import profileReducer from './profile';
import permissionsRolesReducer from "./permissionsRoles";

const Reducers = combineReducers({
  auth: authReducer,
  error: errorReducer,
  register: registerReducer,
  user: userReducer,
  settings: settingsReducer,
  subscription: subscriptionReducer,
  profile: profileReducer,
  permissionsRoles: permissionsRolesReducer,
});

export default Reducers;
