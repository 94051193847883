import { gql } from '@apollo/client';

const NOTIFICATIONS_CONFIG = gql`
  mutation NOTIFICATIONS_CONFIG(
    $subscription_update: Boolean!
    $beneficiary_update: Boolean!
  ) {
    notificationsConfig(
      subscription_update: $subscription_update
      beneficiary_update: $beneficiary_update
    ) {
      user_id
      subscription_update
      beneficiary_update
    }
  }
`;
export default NOTIFICATIONS_CONFIG;
