import { gql } from '@apollo/client';

const CANCELATION = gql`
  mutation CANCELATION {
    createCancelation(reason: "", data: "", type: "subscription") {
      id
    }
  }
`;
export default CANCELATION;
