import { gql } from '@apollo/client';

const ADDCONTACT = gql`
  mutation AddContact(
    $email_contact: String
    $dni: String
    $dni_type: String!
    $first_name: String!
    $last_name: String!
    $mobile: String
    $email: String!
    $dependent: Boolean!
  ) {
    addContact(
      email_contact: $email_contact
      dni_type: $dni_type
      dni: $dni
      first_name: $first_name
      last_name: $last_name
      email: $email
      mobile: $mobile
      dependent: $dependent
    ) {
      id
      first_name
      last_name
    }
  }
`;

export default ADDCONTACT;
