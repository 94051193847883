import { gql } from '@apollo/client';

// Page starts with 1
const COUPON = gql`
  query Coupon($id: String!) {
    coupon(id: $id) {
      id
      name
      description
      discount_value
      percentage_value
      apply_to_addons
      apply_to_plans
      beneficiary
      coupon_code
      max_redemption
      status
      plans {
        id
        plan_type
        name
      }
      addons {
        id
        type
      }
    }
  }
`;

export default COUPON;
