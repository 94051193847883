import {
  CLEAR_USER_DATA,
  GET_USER_DATA,
  SET_USER_DATA,
  CLEAR_CACHE,
  SET_CACHE,
  SET_PROFILE,
  SET_USER_ROLES,
  CLEAR_USER_ROLES,
} from './types';

export const login = (role) => ({
  type: 'LoggedIn',
  payload: role,
});

export const me = (user) => ({
  type: GET_USER_DATA,
  payload: user,
});

export const setUserData = (user) => ({
  type: SET_USER_DATA,
  payload: user,
});

export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
  payload: {},
});

export const clearCache = () => ({
  type: CLEAR_CACHE,
  payload: {},
});

export const setCache = (state) => ({
  type: SET_CACHE,
  payload: state,
});

export const setProfile = (profile) => ({
  type: SET_PROFILE,
  payload: profile,
});

export const setUserRoles = (roles) => ({
  type: SET_USER_ROLES,
  payload: roles,
});

export const clearUserRoles = () => ({
  type: CLEAR_USER_ROLES,
  payload: [],
});