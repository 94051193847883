import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import calculatePriceBreakdown from '../../../../utils/priceBreakdown';

const PriceBox = () => {
  const currentPlan = useSelector((state) => state.register.currentPlan);
  const coupon = useSelector((state) => state.register.coupon);
  const beneficiaries = useSelector((state) => state.register.beneficiaries);
  const [priceBreakdown, setPriceBreakdown] = useState({});

  useEffect(() => {
    setPriceBreakdown(calculatePriceBreakdown(currentPlan, coupon, beneficiaries.length));
  }, [currentPlan, coupon, beneficiaries]);

  return (
    <div className="daily-rectangle">
      <div className="daily-head">
        {currentPlan.name}
      </div>
      {currentPlan.trial_period && (
      <div className="daily-list">
        <p>Periodo de prueba de {currentPlan.trial_period + ' días'}</p>
      </div>)}
      <div className="daily-list">
        <span>Plan:</span>
        <span>
          {'$'} {priceBreakdown.planPrice}
        </span>
      </div>
      {beneficiaries.length > 0 && (
        <div className="daily-list">
          <span>{beneficiaries.length} Adicional(es):</span>
          <span>
            {'$'} {priceBreakdown.beneficiariesPrice}
          </span>
        </div>
      )}
      {coupon.id && (
        <div className="daily-list">
          <span>Descuento:</span>
          <span className="discount">
            {'-$'} {priceBreakdown.discount}
          </span>
        </div>
      )}
      <div className="daily-list">
        <span>Subtotal:</span>
        <span>
          {'$'} {priceBreakdown.subtotal}
        </span>
      </div>

      <div className="daily-list">
        <span>I.V.A.:</span>
        <span>
          {'$'} {priceBreakdown.ivaTotal}
        </span>
      </div>
      <div className="daily-list total">
        <span>Total:</span>
        <span>
          {'$'} {priceBreakdown.total}
        </span>
      </div>
    </div>
  );
};

export default PriceBox;
