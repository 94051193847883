const greenStatus = {
  future: "por activar",
  live: "activo",
  trial: "prueba",
  non_renewing: "activo"
};

const redStatus = {
  cancelled: "cancelado",
  dunning: "reclamo",
  expired: "expirado",
  paused: "pausado",
  pending_payment: "pago pendiente",
  trial_expired: "prueba expirada",
  unpaid: "pendiente",
};

export { greenStatus, redStatus };
