import {
  SET_NEW_PLAN,
  TOGGLE_ADDON,
  ADD_ADDON,
  ADD_BENEFICIARY,
  CLEAR_BENEFICIARIES,
  CLEAR_ADDONS,
} from '../actions/types';

export const initialState = {
  newPlan: {},
  addons: [],
  beneficiaries: [],
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_PLAN:
      return { ...state, newPlan: { ...action.payload } };
    case CLEAR_ADDONS:
      return { ...state, addons: [] };
    case TOGGLE_ADDON:
      const toggledAddon = action.payload;
      const foundIndex = state.addons.findIndex(
        (addon) => addon.id === toggledAddon.id
      );
      if (foundIndex === -1) {
        return { ...state, addons: [...state.addons, toggledAddon] };
      } else {
        const addonsWithoutAddon = [...state.addons];
        addonsWithoutAddon.splice(foundIndex, 1);
        return { ...state, addons: [...addonsWithoutAddon] };
      }
    case ADD_ADDON:
      const addonAdded = action.payload;
      const foundAddedIndex = state.addons.findIndex(
        (addon) => addon.id === addonAdded.id
      );
      if (foundAddedIndex === -1) {
        return { ...state, addons: [...state.addons, addonAdded] };
      }
      return { ...state };
    case ADD_BENEFICIARY:
      return {
        ...state,
        beneficiaries: [...state.beneficiaries, action.payload],
      };
    case CLEAR_BENEFICIARIES:
      return {
        ...state,
        beneficiaries: [],
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
