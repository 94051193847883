import { SET_USER_DATA, CLEAR_USER_DATA } from '../actions/types';

export const initialState = {
  email: '',
  mobile: '',
  first_name: '',
  last_name: '',
  maturity_date: '',
  card_number: '',
  holder_name: '',
  status: '',
  subscription: {
    plan: {
      name: '',
      months: '',
      plan_type: '',
    },
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, ...action.payload };
    case CLEAR_USER_DATA:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
