import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { Stepper } from 'react-form-stepper';
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { register as registerAction } from '../../../state/actions/register';
import { Queries } from '../../../Queries';

import Header from '../header';
import './index.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import TagManager from 'react-gtm-module';
import { gtmNames } from '../../../constants';
import { utmParameters as utms }  from "../../../constants";

const Registration = () => {
  const dispatch = useDispatch();
  // Use location to parse 'plan' and 'coupon' query strings from the url
  const location = useLocation();
  const history = useHistory();
  const currentStep = useSelector((state) => state.register.currentStep);
  const currentPlan = useSelector((state) => state.register.currentPlan);
  const visitedFirstStep = useSelector(
    (state) => state.register.visitedFirstStep
  );
  const [getPlan, { data }] = useLazyQuery(Queries.PLAN);
  const [getCoupon, { data: couponData }] = useLazyQuery(Queries.COUPON);
  const { path } = useRouteMatch();

  useEffect(() => {
    // parse query strings from the url
    const searchParams = new URLSearchParams(location.search);
    const planId = searchParams.get('pc');
    const couponId = searchParams.get('cp');
    const salesPersonName = searchParams.get('sp');
    
    // Campaign parameters.
    const utmParameters = {
      utm_source:   searchParams.get(utms.utm_source)   || '',
      utm_medium:   searchParams.get(utms.utm_medium)   || '',
      utm_campaign: searchParams.get(utms.utm_campaign) || '',
      utm_term:     searchParams.get(utms.utm_term)     || '',
      utm_content:  searchParams.get(utms.utm_content)  || '',
    };

    dispatch(registerAction({ utmParameters }));

    if (planId) {
      getPlan({ variables: { id: planId } });
    }
    if (couponId) {
      getCoupon({ variables: { id: couponId } });
      dispatch(registerAction({ couponId: couponId }));
    }
    if (salesPersonName) {
      dispatch(registerAction({ salesperson_name: salesPersonName }));
    }

    // Triggers GTM
    TagManager.dataLayer({
      dataLayer: {
        event: gtmNames.checkoutBegin,
      },
    });
  }, []);

  useEffect(() => {
    if (couponData) {
      dispatch(registerAction({ coupon: couponData.coupon }));
    }
  }, [couponData]);

  useEffect(() => {
    if (data) {
      const { plan } = data;
      dispatch(registerAction({ currentPlan: plan }));
    }
  }, [data]);

  // If you refresh the page you reset redux and restart at step 1
  useEffect(() => {
    const { pathname } = location;
    // regex to check if its the first step of the register
    const firstStep = /plan\/?(1)?\/?/;
    if (firstStep.test(pathname)) {
      if (!visitedFirstStep) {
        history.push('/plan/1');
      }
    }
  }, []);

  return (
    <>
      <Header />
      <section className="form-section">
        <Container className="position-relative">
          <h1 className="main-title">
            {currentPlan.name ? currentPlan.name : 'Adquirí tu Plan Mensual'}
          </h1>
          <p className="sub-title">
            Ingresá los datos que se te indican y obtené control de tu salud.
          </p>
          <Stepper
            className="stepper"
            steps={[
              { label: currentStep === 1 || currentStep > 1 ? 'TITULAR' : '' },
              { label: currentStep === 2 || currentStep > 2 ? 'CONTACTO' : '' },
              { label: currentStep === 3 || currentStep > 3 ? 'PLAN' : '' },
              { label: currentStep === 4 || currentStep > 4 ? 'PAGO' : '' },
              { title: '' },
              // { label: 'CONTACTO' },
              // { label: 'PLAN' },
              // { label: 'PAGO' },
            ]}
            activeStep={currentStep}
            completeColor="#944FF7"
            activeTitleColor="#944FF7"
            completeBorderStyle="#944FF7"
            // eslint-disable-next-line
            className={'stepclass'}
            stepClassName="stepclassName"
          />
          <Switch>
            <Route path={`${path}/1`}>
              <Step1 />
            </Route>
            <Route path={`${path}/2`}>
              <Step2 />
            </Route>
            <Route path={`${path}/3`}>
              <Step3 />
            </Route>
            <Route path={`${path}/4`}>
              <Step4 />
            </Route>
            <Route path={`${path}/5`}>
              <Step5 />
            </Route>
            {/* Default route */}
            <Route>
              <Step1 />
            </Route>
          </Switch>
        </Container>
      </section>
    </>
  );
};

export default Registration;
