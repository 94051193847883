import * as couponStatuses from "../constants/register/couponStatuses";

// If the coupon applied produces an error (i.e: is expired, invalid...) or is
// being typed again, it's necessary to clean the 'coupon' field in the register
// state to avoid issues in the PriceBox component.
const cleanCoupon = {
  id: "",
  name: "",
  description: "",
  discount_value: "",
};
const defaultErrorMessage = "Cupón inválido";

const validateCoupon = (coupon, currentPlan) => {
  let defaultResponse = {
    coupon: cleanCoupon,
    message: defaultErrorMessage,
    error: true,
  };
  if (!coupon.plans && coupon.apply_to_plans !== "all" && !coupon.beneficiary) {
    return defaultResponse;
  }

  const status = coupon.status.toLowerCase();
  const isValidCoupon = validateCouponForPlan(coupon, currentPlan);  

  if (!isValidCoupon) {
    defaultResponse.message = "El cupón no aplica para el plan seleccionado";
  } else if (couponStatuses.unacceptableTypes[status]) {
    defaultResponse.message = couponStatuses.unacceptableTypes[status];
  } else {
    return {
      coupon,
      message: "¡Se ha aplicado correctamente un código promocional a tu plan!",
      error: false,
    }
  }
  return defaultResponse;
}

const validateCouponForPlan = (coupon, currentPlan) => {
  if (coupon.beneficiary || !coupon.plans && coupon.apply_to_plans === "all") return true;
  return coupon.plans?.some(plan => 
    plan.name.toLowerCase() === currentPlan.name.toLowerCase()
  );
}

export { cleanCoupon, defaultErrorMessage, validateCoupon };