import { gql } from '@apollo/client';

const REGISTER = gql`
  mutation Register(
    $email: String!
    $dni_type: String!
    $dni: String
    $first_name: String!
    $last_name: String!
    $mobile: String!
  ) {
    register(
      email: $email
      dni_type: $dni_type
      dni: $dni
      first_name: $first_name
      last_name: $last_name
      mobile: $mobile
    ) {
      id
    }
  }
`;

export default REGISTER;
