import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Stepper } from 'react-form-stepper';
import { Switch, Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import Header from '../../header';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const Beneficiaries = () => {
  const location = useLocation();
  const history = useHistory();
  const currentStep = useSelector((state) => state.register.beneficiariesCurrentStep);
  const currentPlan = useSelector((state) => state.register.currentPlan);
  const { path } = useRouteMatch();
  const visitedFirstStep = useSelector(
    (state) => state.register.visitedFirstStep,
  );
  // If you refresh the page you reset redux and restart at step 1
  useEffect(() => {
    const { pathname } = location;
    // regex to check if its the first step of the register
    const firstStep = /plan(\/)?(1)?\/?/;
    if (!firstStep.test(pathname)) {
      if (!visitedFirstStep) {
        history.push('/plan/1');
      }
    }
  }, []);

  return (
    <>
      <Header />
      <section className="form-section">
        <Container>
          <h1 className="main-title">
            Protegé a los tuyos
          </h1>
          <p className="sub-title">
            Ingresá los datos que se te indican para agregar a la persona adicional.
          </p>
          <Stepper
            className="stepper"
            steps={[
              { label: currentStep === 0 || currentStep > 0 ? 'ADICIONAL' : '' },
              { label: currentStep === 1 || currentStep > 1 ? 'CONTACTO' : '' },
              { label: currentStep === 2 || currentStep > 2 ? 'PLAN' : '' },
              { title: '' },
              // { label: 'CONTACTO' },
              // { label: 'PLAN' },
              // { label: 'PAGO' },
            ]}
            activeStep={currentStep}
            completeColor="#944FF7"
            activeTitleColor="#944FF7"
            completeBorderStyle="#944FF7"
            // eslint-disable-next-line
            className={'stepclass'}
            stepClassName="stepclassName"
          />
          <Switch>
            <Route path={`${path}/1`}>
              <Step1 />
            </Route>
            <Route path={`${path}/2`}>
              <Step2 />
            </Route>
            <Route path={`${path}/3`}>
              <Step3 />
            </Route>
          </Switch>
        </Container>
      </section>
    </>
  );
};

export default Beneficiaries;
