const utm_source = 'utm_source';
const utm_medium = 'utm_medium';
const utm_campaign = 'utm_campaign';
const utm_term = 'utm_term';
const utm_content = 'utm_content';

export { 
  utm_source, 
  utm_medium, 
  utm_campaign, 
  utm_term, 
  utm_content 
};