import { gql } from '@apollo/client';

const UPDATE_CUSTOMER = gql`
  mutation UPDATE_CUSTOMER(
    $email: String
    $password: String
    $first_name: String
    $last_name: String
    $mobile: String
    $card_holder: String
    $card_exp_month: String
    $card_exp_year: String
    $card_token: String
    $card_brand: String
    $card_masked: String
  ) {
    updateCustomer(
      email: $email
      password: $password
      first_name: $first_name
      last_name: $last_name
      mobile: $mobile
      card_holder: $card_holder
      card_exp_month: $card_exp_month
      card_exp_year: $card_exp_year
      card_token: $card_token
      card_brand: $card_brand
      card_masked: $card_masked
    ) {
      id
      first_name
      last_name
      customer_type
      email
      dni
      mobile
      card_number
      maturity_date
      holder_name
    }
  }
`;
export default UPDATE_CUSTOMER;
