const env = process.env.NODE_ENV || 'development';
const errorHandler = ({ graphQLErrors, networkError }, setError) => {
  try {
    let msg;
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => {
        msg = message;
        if (env === 'development') {
          // console.error(message);
        }
        return msg;
      });
    }
    if (networkError) {
      if (env === 'development') {
        networkError.result.errors.map(({ message }) => {
          msg = message;
          return msg;
        });
      }
      msg = 'Error de conexión o solicitud';
    }
    if (setError) {
      setError(msg);
    }
    return msg;
  } catch (error) {
    return 'Error desconocido';
  }
};
export default errorHandler;
