import { gql } from '@apollo/client';

const CREATE_ACCOUNT = gql`
  mutation CreateAccount(
    $card_brand: String!
    $card_exp_month: String!
    $card_exp_year: String!
    $card_holder: String!
    $card_masked: String!
    $card_token: String!
    $coupon_id: String
    $email: String!
    $password: String!
    $plan_id: String!
    $salesperson_name: String
    $utm_source: String
    $utm_medium: String
    $utm_campaign: String
    $utm_term: String
    $utm_content: String
  ) {
    createAccount(
      plan_id: $plan_id
      email: $email
      password: $password
      card_holder: $card_holder
      card_exp_month: $card_exp_month
      card_exp_year: $card_exp_year
      card_masked: $card_masked
      card_brand: $card_brand
      card_token: $card_token
      coupon_id: $coupon_id
      salesperson_name: $salesperson_name
      utm_source: $utm_source
      utm_medium: $utm_medium
      utm_campaign: $utm_campaign
      utm_term: $utm_term
      utm_content: $utm_content
    ) {
      user {
        id
        first_name
        last_name
        customer_type
        email
        dni
        mobile
        status
      }
      tokens {
        token
        refresh_token
      }
      permissions_roles
    }
  }
`;

export default CREATE_ACCOUNT;
