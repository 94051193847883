import { gql } from '@apollo/client';

// Page starts with 1
const CUSTOMERS = gql`
  query CUSTOMERS($page: Int!) {
    customers(page: $page) {
      id
      first_name
      last_name
      customer_type
      email
      dni
      mobile
      status
      created_at
      subscription {
        amount
        id
        created
        plan {
          id
          name
          months
          price
          tax_id
        }
      }
      beneficiaries {
        id
        first_name
        last_name
        customer_type
        email
        dni
        mobile
        status
        subscription {
          id
          created
          plan {
            id
            name
            months
            price
            tax_id
          }
        }
      }
      payments {
        date
      }
    }
  }
`;
export default CUSTOMERS;
