/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Error404Page from '../Component/Error404Page';
import { login } from '../state/actions/index';
import Registration from '../Component/Layout/Registration';
import Beneficiaries from '../Component/Layout/Registration/Beneficiaries';
import Done from '../Component/Layout/Registration/Done';

const Login = React.lazy(() => import('../Component/Login'));
const ForgotPassword = React.lazy(() => import('../Component/ForgotPassword'));
const Dashboard = React.lazy(() => import('../Component/Dashboard/Dashboard'));
const PublicSearch = React.lazy(() => import('../Component/PublicSearch'));

const AppRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(login(localStorage.getItem('login')));
  });
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Login /> }
      />

      {/* Registration of new user */}
      <Route path="/plan">
        <Registration />
      </Route>

      <Route path="/beneficiario">
        <Beneficiaries />
      </Route>

      <Route path="/done">
        <Done />
      </Route>

      <Route
        exact
        path="/Login"
        render={() => <Login /> }
      />

      <Route
        exact
        path="/ForgotPassword"
        component={ForgotPassword}
      />

      <Route
        exact
        path="/search"
        component={PublicSearch}
      />

      <Route
        path="/dashboard"
        render={(renderProps) => <Dashboard {...renderProps} /> }
      />

      <Route
        path="/dashboard/admin"
        render={(renderProps) => <Dashboard {...renderProps} /> }
      />
      
      <Route render={() => <Error404Page />} />
    </Switch>
  );
};
export default withRouter(AppRoutes);
