import LOGIN from './login';
import ME from './me';
import NOTIFICATIONS_CONFIG from './notificationsConfigInfo';
import CUSTOMER from './customer';
import CUSTOMERS from './customers';
import SEARCH from './search';
import PUBLIC_SEARCH from './publicSearch';
import CANCELATION_REQUESTS from './cancelationRequests';
import COUPON from './coupon';
import MULTIPLE_SEARCH from './multipleSearch';
import PLANS from './plans';
import ADDONS from './addons';
import CREATE_ACCOUNT from './createAccount';
import PLAN from './plan';

const Queries = {
  PLAN,
  ADDONS,
  CREATE_ACCOUNT,
  LOGIN,
  ME,
  NOTIFICATIONS_CONFIG,
  CUSTOMER,
  CUSTOMERS,
  SEARCH,
  PUBLIC_SEARCH,
  CANCELATION_REQUESTS,
  COUPON,
  MULTIPLE_SEARCH,
  PLANS,
};
export default Queries;
