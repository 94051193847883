import * as Yup from 'yup';

const schema = Yup.object({
  email: Yup.string()
    .email('Corresponde a un email válido')
    .required('Requerido'),
  contact: Yup.string()
    .min(8, 'Numero tiene que tener mas de ${min} números')
    .required('Requerido'),
});

export default schema;
