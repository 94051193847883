export const initialState = {
  error: {
    hasError: false,
    screen: '',
    message: '',
  },
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'Error':
      return { ...state, error: { ...action.payload } };
    default:
      return state;
  }
};

export default errorReducer;
