import React from 'react';
import loader from '../../assets/img/loader.svg';
import './index.scss';

const Loader = ({ fullLoader }) => (
  <div className={`loader-wrap ${fullLoader ? fullLoader : 'inner-loader'}`}>
    <div className="loader-block">
      <img src={loader} alt="loader" />
    </div>
  </div>
);

export default Loader;
