import { gql } from '@apollo/client';

const ADDONS = gql`
  query ADDONS($id: String!) {
    addons(id: $id) {
      id
      name
      description
      plans {
        id
        name
        months
        price
        tax_id
      }
      price
      iva
      type
    }
  }
`;
export default ADDONS;
