import { gql } from '@apollo/client';

const PLAN = gql`
  query Plan($id: String!) {
    plan(id: $id) {
      id
      name
      price
      months
      iva
      total_price
      tax_id
      plan_type
      beneficiary_cost {
        id
        name
        description
        price
        iva
        total_price
        type
      }
      addons {
        description
        id
        name
        iva
        price
        total_price
      }
    }
  }
`;
export default PLAN;

