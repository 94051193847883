import { CLEAR_CACHE, SET_CACHE } from '../actions/types';

const initialState = { clear: false };
const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CACHE:
      return { ...state, clear: true };
    case SET_CACHE: {
      return { ...state, clear: action.payload };
    }
    default:
      return state;
  }
};

export default settingsReducer;
