import { gql } from '@apollo/client';

// Page starts with 1
const CUSTOMER = gql`
  query CUSTOMER($id: String!) {
    customer(id: $id) {
      id
      first_name
      last_name
      customer_type
      email
      dni
      dni_type
      mobile
      status
      holder_name
      card_number
      maturity_date
      subscription {
        id
        created
        trial_days
        amount
        status
        plan {
          id
          name
          months
          price
          tax_id
        }
        addons {
          id
          name
          description
          plans {
            id
            name
            months
            price
            tax_id
          }
          price
        }
      }
      beneficiaries {
        id
        first_name
        last_name
        customer_type
        email
        dni
        mobile
        status
        subscription {
          id
          created
          plan {
            id
            name
            months
            price
            tax_id
          }
          addons {
            id
            name
            description
            plans {
              id
              name
              months
              price
              tax_id
            }
            price
          }
        }
        payments {
          date
        }
      }
      payments {
        date
        card_type
        plan
        card_masked
        status
        amount
      }
    }
  }
`;
export default CUSTOMER;
