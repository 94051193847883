import { gql } from '@apollo/client';

const UPDATE_ADDONS = gql`
  mutation UPDATE_ADDONS($addons_list: [String!]) {
    updateAddons(addons_list: $addons_list) {
      id
      plan {
        id
      }
    }
  }
`;
export default UPDATE_ADDONS;
