export const initialState = {
  beneficiaryCurrentStep: 0,
  currentBeneficiary: {
    dni: '',
    dniType: '',
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    country: '',
    dependent: '',
  },
  visitedFirstStep: false,
  beneficiaries: [],
  dni: '',
  dniType: '',
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  country: '',
  salesperson_name: '',
  cardInfo: {
    cardToken: '',
    cvv: '',
    cardNumber: '',
    expMonth: '',
    expYear: '',
    cardHolder: '',
    couponId: '',
    cardBrand: '',
  },
  coupon: {
    id: '',
    name: '',
    description: '',
    discount_value: '',
  },
  currentPlan: {
    id: '',
    plan: '',
    name: '',
    months: '',
    price: '',
    iva: '',
    totalPrice: '',
    taxId: '',
    beneficiaryCost: '',
    addons: '',
  },
  utmParameters: {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  },
  authToken: {
  },
  currentStep: 0,
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'updateRegisterData':
      return { ...state, ...action.payload };
    case 'addBeneficiary':
      return { ...state, beneficiaries: [...state.beneficiaries, action.payload] };
    default:
      return state;
  }
};

export default registerReducer;
