import React from 'react';
import Header from '../../header';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login } from '../../../../state/actions/index';
import { Button } from 'react-bootstrap';
import './style.css';

const Done = () => {
  const authToken = useSelector((state) => state.register.authToken);
  const history = useHistory();
  const dispatch = useDispatch();

  const onClick = () => {
    // Login after creating the account
    if (authToken && authToken.createAccount) {

      localStorage.setItem('token', JSON.stringify(authToken?.createAccount?.tokens?.token));
      localStorage.setItem(
        'refresh_token',
        JSON.stringify(authToken?.createAccount?.tokens?.refresh_token)
      );
      localStorage.setItem('userRoles', JSON.stringify(authToken?.createAccount?.permissions_roles));
      const { user } = authToken.createAccount;

      if (user.customer_type === 'Admin') {
        window.localStorage.setItem('loggedInEmail', user.email);
        localStorage.setItem('login', 'admin');
        dispatch(login('admin'));
      } else {
        localStorage.setItem('login', 'user');
        dispatch(login('user'));
      }
      history.push('/dashboard');
    }
  };

  return (
    <>
      <Header />
      <Container>
        <div className="row justify-content-center">
          <div className="col-7">
            <h1 className="done-title">Bienvenido a Salud 360</h1>
            <p className="done-subtitle">
              ¡Felicidades, ya podés utilizar tu plan y tomar control de tu salud! Al presentarte en
              tu cita, sólo debés indicar que es para Salud 360, brindar tu número de cédula y el
              centro médico o farmacia verificará en sistema, y listo :)
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <Button
            variant="link"
            className="btn-theme-outline mb-2"
            onClick={onClick}
            disabled={false}
          >
            Iniciar sesión
          </Button>
        </div>
      </Container>
    </>
  );
};

export default Done;
