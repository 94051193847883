import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import '../index.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { register as registerAction } from '../../../../state/actions/register';
import Loader from '../../../../Common/Loader/Loader';
import { Queries } from '../../../../Queries';
import TagManager from 'react-gtm-module';
import { gtmNames } from '../../../../constants';
import calculatePriceBreakdown from '../../../../utils/priceBreakdown';
import { Checkbox } from '@material-ui/core';
import AppLabel from '../../../AppLabel';

const Step5 = () => {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register);
  const history = useHistory();
  const [queryError, setQueryError] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const schema = Yup.object({
    name: Yup.string().required('Requerido'),
    email: Yup.string().email().required(),
    password: Yup.string()
      .min(8, 'Mínimo 8 caracteres')
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
        'Utilizá 8 o más caracteres con una combinación de minúsculas, mayúsculas y números.'
      ),
    // 8 o mas chars con minus y mayus
  });

  const [createAccount, { loading }] = useMutation(Queries.CREATE_ACCOUNT, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    dispatch(registerAction({ currentStep: 4 }));
  }, []);

  if (loading) return <Loader />;

  return (
    <Formik
      initialValues={{
        name: `${registerData.firstName} ${registerData.lastName}`,
        email: registerData.email,
        password: '',
      }}
      validationSchema={schema}
      validateOnChange
      // change 'redux state' from above when submitting
      onSubmit={async ({ name, email, password }) => {
        const { cardInfo } = registerData;
        let data;

        try {
          const { data: returnData } = await createAccount({
            variables: {
              card_brand: cardInfo.cardBrand,
              card_exp_month: cardInfo.expMonth.toString(),
              card_exp_year: cardInfo.expYear.toString(),
              card_holder: name,
              card_masked: cardInfo.cardNumber,
              card_token: cardInfo.cardToken,
              coupon_id: cardInfo.couponId,
              email,
              password,
              plan_id: registerData.currentPlan.id,
              salesperson_name: registerData.salesperson_name,
              utm_source: registerData.utmParameters.utm_source,
              utm_medium: registerData.utmParameters.utm_medium,
              utm_campaign: registerData.utmParameters.utm_campaign,
              utm_term: registerData.utmParameters.utm_term,
              utm_content: registerData.utmParameters.utm_content,
            },
          });
          data = returnData;
        } catch (error) {
          setQueryError(error.message);
          return;
        }
        dispatch(registerAction({ authToken: data }));
        // Trigger gtm
        TagManager.dataLayer({
          dataLayer: {
            event: gtmNames.checkoutEnd,
            subscriptionValue: calculatePriceBreakdown(
              registerData.currentPlan,
              registerData.coupon,
              registerData.beneficiaries.length
            ).total,
          },
        });
        history.push('/done');
      }}
    >
      {(formik) => (
        <>
          <Form className="resgistration-form center-form">
            <div className="form-header">
              <h2 className="form-title">
                <span>Crear cuenta</span>
              </h2>
            </div>
            <Form.Group controlId="formBasicNumber">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                name="name"
                readOnly
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Correo electrónico</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                readOnly
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Introducir contraseña"
                name="password"
                className={formik.touched.password && formik.errors.password ? 'is-invalid' : ''}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.password && formik.errors.password && (
                <span className="error-text">{formik.errors.password}</span>
              )}
            </Form.Group>
            <Form.Group>
              {queryError && <span className="error-text">{queryError}</span>}
            </Form.Group>
            <div className="d-flex align-items-center">
              <Checkbox
                color="primary"
                name="agree"
                onChange={() => setAgreeTerms(!agreeTerms) }
              />
              <AppLabel
                className="terms-and-conditions"
                label={
                <span>Acepto los&nbsp;
                  <a
                    href="https://www.salud360.cr/terminos-de-uso/"
                    target="_blank"
                  >
                    términos y condiciones
                  </a>
                </span>} 
              />
            </div>
          </Form>
          <div className="d-flex align-items-center justify-content-between btn-wrap">
            <Button
              variant="link"
              className="btn-theme-outline mb-2"
              onClick={() => {
                history.push('/plan/4');
              }}
              disabled={false}
            >
              Atrás
            </Button>
            <Button
              variant="link"
              className="btn-theme mb-2"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty && agreeTerms)}
            >
              Completar
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};
export default Step5;
