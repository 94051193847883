//  User
export const GET_USER_DATA = 'GET_USER_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const CLEAR_USER_ROLES = 'CLEAR_USER_ROLES';

// Settings
export const CLEAR_CACHE = 'CLEAR_CACHE';
export const SET_CACHE = 'SET_CACHE';

// Subscription
export const SET_NEW_PLAN = 'SET_NEW_PLAN';
export const TOGGLE_ADDON = 'TOGGLE_ADDON';
export const CLEAR_ADDONS = 'CLEAR_ADDONS';
export const ADD_ADDON = 'ADD_ADDON';
export const ADD_BENEFICIARY = 'ADD_BENEFICIARY';
export const CLEAR_BENEFICIARIES = 'CLEAR_BENEFICIARIES';
