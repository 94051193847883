import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Id from './id';
import identificationOptions from '../../../../Common/identificationOptions.json';
import { register as registerAction } from '../../../../state/actions/register';

import '../index.css';

const Step1Component = () => {
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register);
  const [dniType, setDniType] = useState(registerData.dniType || identificationOptions[0].name);
  const history = useHistory();

  useEffect(() => {
    dispatch(registerAction({ currentStep: 0 }));
  }, []);

  async function onSubmit(idNumber, firstName, lastName, country) {
    dispatch(registerAction({ dni: idNumber, firstName, lastName, dniType, country }));
    dispatch(registerAction({ visitedFirstStep: true }));
    history.push('/plan/2');
  }

  return (
    <>
      <Form className="resgistration-form center-form">
        <div className="form-header">
          <h2 className="form-title">
            <span>Titular del plan</span>
          </h2>
          <p className="form-information">
            Obtuvimos estos datos del Registro Civil de Costa Rica, por favor verificá que sean correctos.
          </p>
        </div>
        <Form.Group controlId="formBasicNumber">
          <Form.Label>Tipo de identificación</Form.Label>
          <Form.Control
            as="select"
            value={dniType}
            name="type"
            onChange={(e) => {
              const { value } = e.target;
              setDniType(value);
            }}
          >
            {identificationOptions.map((list) => (
              <option value={list.name} key={list.key}>
                {list.name}{' '}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>
      <Id
        type={dniType}
        onSubmit={onSubmit}
        defaultFirstName={registerData.firstName}
        defaultIdNumber={registerData.dni}
        defaultLastName={registerData.lastName}
      />
    </>
  );
};

export default Step1Component;
