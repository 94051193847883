import * as Yup from 'yup';

const schema = Yup.object({
  name: Yup.string().required('Requerido'),
  cardNumber: Yup.string()
    .min(8, 'Numero tiene que tener mas de ${min} números')
    .required('Requerido'),
  expirationDate: Yup.string().min(4, 'Fecha invalida').required('Requerido'),
  cvv: Yup.string().min(3).required(),
  promoCode: Yup.string(),
});

export default schema;
