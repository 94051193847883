import UPDATE_CUSTOMER from './updateCustomer';
import NOTIFICATIONS_CONFIG from './notificationsConfig';
import ADDCONTACT from './addContact';
import REGISTER from './register';
import CANCEL_EXISTING_SUBSCRIPTION from './cancelExistingSubscrption';
import REMOVE_CANCELATION from './removeCancelation';
import ADD_ADDONS from './addAddons';
import CREATE_CANCELATION from './createCancelation';
import UPDATE_PLAN from './updatePlan';
import UPDATE_ADDONS from './updateAddons';

const Mutations = {
  UPDATE_ADDONS,
  CREATE_CANCELATION,
  UPDATE_PLAN,
  ADD_ADDONS,
  UPDATE_CUSTOMER,
  NOTIFICATIONS_CONFIG,
  ADDCONTACT,
  REGISTER,
  CANCEL_EXISTING_SUBSCRIPTION,
  REMOVE_CANCELATION,
};
export default Mutations;
