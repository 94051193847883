import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import CountryWithCode from '../../../../../Common/CountryWithCode.json';
import { Mutations } from '../../../../../Queries';
import Loader from '../../../../../Common/Loader/Loader';
import {
  register as registerAction,
  addBeneficiary as addBeneficiaryAction,
} from '../../../../../state/actions/register';
import TagManager from 'react-gtm-module';
import { gtmNames } from '../../../../../constants';

const Step2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addContact, { loading }] = useMutation(Mutations.ADDCONTACT, {
    fetchPolicy: 'no-cache',
  });
  const [queryError, setQueryError] = useState('');

  const currentBeneficiary = useSelector((state) => state.register.currentBeneficiary);
  const userContactEmail = useSelector((state) => state.register.email);

  const data = CountryWithCode.filter(
    (county) => county.name.toLowerCase() === currentBeneficiary.country?.toLowerCase()
  )[0];
  let temp = '+506';
  if (data?.name) {
    temp = `${data.code} ${data.dial_code}`;
  }

  useEffect(() => {
    dispatch(registerAction({ beneficiariesCurrentStep: 1 }));
  }, []);

  const schema = Yup.object({
    email: Yup.string().email('Corresponde a un email valido').required('Requerido'),
    contact: Yup.string()
      .min(8, 'Numero tiene que tener mas de ${min} números')
      .required('Requerido'),
  });

  async function onSubmit({ email, contact }) {
    try {
      await addContact({
        variables: {
          email_contact: email,
          dni: currentBeneficiary.dni,
          first_name: currentBeneficiary.firstName,
          last_name: currentBeneficiary.lastName,
          dni_type: currentBeneficiary.dniType,
          email: userContactEmail,
          mobile: contact,
          dependent: currentBeneficiary.dependent,
        },
      });
    } catch (error) {
      setQueryError(error.message);
      return;
    }
    TagManager.dataLayer({
      dataLayer: {
        event: gtmNames.adicionalAddInfo,
      },
    });
    dispatch(
      addBeneficiaryAction({
        dni: currentBeneficiary.dni,
        first_name: currentBeneficiary.firstName,
        last_name: currentBeneficiary.lastName,
        dni_type: currentBeneficiary.dniType,
        email: userContactEmail,
        mobile: contact,
        email_contact: email,
        dependent: currentBeneficiary.dependent,
      })
    );
    dispatch(registerAction({ currentBeneficiary: {} }));
    history.push('/beneficiario/3');
  }
  if (loading) return <Loader />;

  return (
    <>
      <Formik
        initialValues={{
          email: currentBeneficiary.email,
          contact: currentBeneficiary.mobile,
        }}
        validationSchema={schema}
        validateOnChange
        // change 'redux state' from above when submitting
        onSubmit={onSubmit}
      >
        {(formik) => (
          <>
            <Form className="resgistration-form center-form">
              <div className="form-header">
                <h2 className="form-title">
                  <span>Información de Contacto</span>
                </h2>
              </div>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="ejemplo@ejemplo.com"
                  name="email"
                  className={formik.errors.email ? 'is-invalid' : ''}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="error-text">{formik.errors.email}</span>
                )}
              </Form.Group>
              <Form.Group>
                <div className="input-group-custom">
                  <div className="input-group-code">
                    <Form.Label>Código país</Form.Label>
                    <div className="append-custom-input">{temp}</div>
                  </div>
                  <div className="input-group-country">
                    <Form.Label>Teléfono</Form.Label>
                    <Form.Control
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      type="text"
                      placeholder="0000 0000"
                      name="contact"
                      className={formik.errors.contact ? 'is-invalid' : ''}
                      value={formik.values.contact}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                {formik.touched.contact && formik.errors.contact && (
                  <span className="error-text">{formik.errors.contact}</span>
                )}
              </Form.Group>
              <Form.Group>
                {queryError && <span className="error-text">{queryError}</span>}
              </Form.Group>
            </Form>
            <div className="d-flex align-items-center justify-content-between btn-wrap">
              <Button
                variant="link"
                className="btn-theme-outline mb-2"
                onClick={() => {
                  history.push('/beneficiario/1');
                }}
                disabled={false}
              >
                Atrás
              </Button>
              <Button
                variant="link"
                className="btn-theme mb-2"
                onClick={formik.handleSubmit}
                disabled={!(formik.isValid && formik.dirty)}
              >
                Agregar
              </Button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default Step2;
