import { gql } from '@apollo/client';

const UPDATE_PLAN = gql`
  mutation UPDATE_PLAN($id: String!) {
    updatePlan(id: $id) {
      id
      created
    }
  }
`;
export default UPDATE_PLAN;
