import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import Id from '../../Step1/id';
import identificationOptions from '../../../../../Common/identificationOptions.json';
import { Mutations } from '../../../../../Queries';
import Loader from '../../../../../Common/Loader/Loader';
import {
  register as registerAction,
  addBeneficiary as addBeneficiaryAction,
} from '../../../../../state/actions/register';
import { gtmNames } from '../../../../../constants';
import TagManager from 'react-gtm-module';

import '../../index.css';

const Step1Component = () => {
  const [addContact, { loading }] = useMutation(Mutations.ADDCONTACT, {
    fetchPolicy: 'no-cache',
  });
  const [queryError, setQueryError] = useState('');
  const dispatch = useDispatch();
  const registerData = useSelector((state) => state.register.currentBeneficiary);
  const userContactEmail = useSelector((state) => state.register.email);
  const userContactMobile = useSelector((state) => state.register.mobile);
  // const userContactEmail = useSelector((state) => state.register.dniType);
  const [dniType, setDniType] = useState(registerData.dniType || identificationOptions[0].name);
  const history = useHistory();

  useEffect(() => {
    dispatch(registerAction({ beneficiariesCurrentStep: 0 }));
  }, []);

  // eslint-disable-next-line react/no-multi-comp
  async function onSubmit(idNumber, firstName, lastName, countryState, dependent) {
    dispatch(
      registerAction({
        currentBeneficiary: {
          dni: idNumber,
          firstName,
          lastName,
          dniType,
          country: countryState,
          dependent,
        },
      })
    );
    // If dependent skip next step and submit the contact to the backend
    if (dependent) {
      try {
        await addContact({
          variables: {
            dni: idNumber,
            first_name: firstName,
            last_name: lastName,
            dni_type: dniType,
            email: userContactEmail,
            mobile: userContactMobile,
            dependent,
          },
        });
      } catch (error) {
        setQueryError(error.message);
        return;
      }
      TagManager.dataLayer({
        dataLayer: {
          event: gtmNames.adicionalAddInfo,
        },
      });
      dispatch(
        addBeneficiaryAction({
          dni: idNumber,
          first_name: firstName,
          last_name: lastName,
          dni_type: dniType,
          email: userContactEmail,
          mobile: userContactMobile,
          email_contact: '',
          dependent,
        })
      );
      dispatch(registerAction({ currentBeneficiary: {} }));
      history.push('/beneficiario/3');
    } else {
      history.push('/beneficiario/2');
    }
  }
  if (loading) return <Loader />;
  return (
    <>
      <Form className="resgistration-form center-form">
        <div className="form-header">
          <h2 className="form-title">
            <span>Información del Adicional</span>
          </h2>
        </div>
        <Form.Group controlId="formBasicNumber">
          <Form.Label>Tipo de identificación</Form.Label>
          <Form.Control
            as="select"
            value={dniType}
            name="type"
            onChange={(e) => {
              const { value } = e.target;
              setDniType(value);
            }}
          >
            {identificationOptions.map((list) => (
              <option value={list.name} key={list.key}>
                {list.name}{' '}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>{queryError && <span className="error-text">{queryError}</span>}</Form.Group>
      </Form>
      <Id
        type={dniType}
        showDependent
        onSubmit={onSubmit}
        defaultFirstName={registerData.firstName}
        defaultIdNumber={registerData.dni}
        defaultLastName={registerData.lastName}
        labelForNameField="Nombre del adicional"
        labelForLastnameField="Apellidos del adicional"
      />
    </>
  );
};

export default Step1Component;
