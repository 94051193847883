/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
import React from 'react';
import { Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createBrowserHistory } from 'history';
import AppRoutes from './routes/';
import './App.css';
import Loader from './Common/Loader/Loader';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { API_ENDPOINT } from './config';
import { setCache } from './state/actions';

const httpLink = createHttpLink({ uri: `${API_ENDPOINT}/graphql` });

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  
  return {
    headers: {
      ...headers,
      'x-token': token || ''
    },
  };
});

//const client = new ApolloClient({
//  link: authLink.concat(httpLink),
//  cache: new InMemoryCache(),
//  onError: (e) => {
//  },
//});

const client =   new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  request: operation => {
    operation.setContext({
      headers:{
        'content-type':'application/json'
      },
      fetchOptions: {
        credentials: 'include',
        mode:'no-cors'
      }
    });
  },
});


const history = createBrowserHistory({ basename: '/' });
const App = () => {
  const { clear } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  if (clear) {
    client
      .clearStore()
      .then((res) => {
        dispatch(setCache(false));
      })
      .catch((err) => {
        dispatch(setCache(false));
      });
  }
  return (
    <>
      <ApolloProvider client={client}>
        <Router history={history}>
          <React.Suspense fallback={<Loader fullLoader />}>
            <AppRoutes />
          </React.Suspense>
        </Router>
      </ApolloProvider>
    </>
  );
};

export default App;
