/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { register as registerAction } from '../../../../state/actions/register';
import PriceBox from '../PriceBox';
import Loader from '../../../../Common/Loader/Loader';
import { Queries } from '../../../../Queries';
import { registerConstants } from '../../../../constants';
import * as couponValidations from '../../../../utils/couponValidations';

const Step3 = () => {
  const dispatch = useDispatch();
  const currentPlanGlobalState = useSelector((state) => state.register.currentPlan);
  const registerData = useSelector((state) => state.register);
  const [urlPromoErrorMessage, setUrlPromoErrorMessage] = useState("");
  const { data, loading } = useQuery(Queries.PLANS);
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setPlans(data.plans);
      // If there was a current plan set by a query string on the url set the default plan
      if (currentPlanGlobalState.id) {
        setCurrentPlan(currentPlanGlobalState);
        setSelectedPlan(currentPlanGlobalState.id);
      } else {
        setCurrentPlan(data.plans[0]);
        setSelectedPlan(data.plans[0].id);
        dispatch(registerAction({ currentPlan: data.plans[0] }));
      }
    }
  }, [data]);

  useEffect(() => {
    if (registerData.couponId && currentPlan) {
      let validatedCoupon = 
        couponValidations.validateCoupon(registerData.coupon, currentPlan);
      let newState = { coupon: { ...validatedCoupon.coupon } };
      if (validatedCoupon.error) {
        newState = { ...newState, couponId: '' };
        setUrlPromoErrorMessage(validatedCoupon.message);
      }
      dispatch(registerAction({ ...newState }));
    }
  }, [currentPlan]);

  useEffect(() => {
    dispatch(registerAction({ currentStep: 2 }));
  }, []);

  function onSubmit() {
    dispatch(registerAction({ currentPlan }));
    history.push('/plan/4');
  }

  function addBeneficiaries() {
    dispatch(registerAction({ currentPlan }));
    history.push('/beneficiario/1');
  }

  if (loading) return <Loader />;
  return (
    <>
      <div className="d-flex plan-container">
        <Form className="resgistration-form">
          <div className="form-header">
            <h2 className="form-title">
              <span>Planes</span>
            </h2>
          </div>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Plan seleccionado</Form.Label>

            {currentPlanGlobalState.id &&
            registerConstants.plans.fixedPlans.findIndex((el) => el === currentPlanGlobalState.id) !==
              -1 ? (
              <h1 className="main-title text-left">{currentPlanGlobalState.name}</h1>
            ) : (
              <Form.Control
                as="select"
                value={selectedPlan}
                onChange={(e) => {
                  const id = e.target.value;
                  const value = plans.find((i) => i.id === id);
                  setSelectedPlan(value.id);
                  setCurrentPlan(value);
                  dispatch(registerAction({ currentPlan: value }));
                }}
                name="duration"
              >
                {plans &&
                  plans
                    // filter fixedPlans
                    .filter(
                      (p) =>
                        !(registerConstants.plans.fixedPlans.findIndex((el) => el === p.id) !== -1)
                    )
                    .map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan.name}
                      </option>
                    ))}
              </Form.Control>
            )}
          </Form.Group>

          <Link to="/beneficiario/1" className="theme-link">
            ¿Querés agregar personas a tu plan?
          </Link>

          <br />
          <br />
          <p className="daily-text">
            Agregá una persona adicional por ${currentPlan.beneficiary_cost?.total_price} al {currentPlan.months === 12 ? "año": "mes"}. (IVA
            incluido) Tus familiares y amigos podrán disfrutar de los mismos beneficios del plan.
          </p>
        </Form>
        <div className="right-box">
        <PriceBox />
        {registerData.coupon.id && (
          <Alert
          show={true}
          dismissible
          className="custom-alert-box custom-alert-box-success"
          >
            ¡Se ha aplicado correctamente un código promocional a tu plan!
          </Alert>
        )}
        {urlPromoErrorMessage && (
        <Alert
          show={true}
          variant="danger"
          onClose={() => { setUrlPromoErrorMessage('') }}
          dismissible
          className="custom-alert-box"
        >
          {urlPromoErrorMessage}
        </Alert>
        )}
      </div>
      </div>
      <div className="d-flex align-items-center justify-content-between btn-wrap">
        <Button
          variant="link"
          className="btn-theme-outline mb-2"
          onClick={() => {
            history.push('/plan/2');
          }}
          disabled={false}
        >
          Atrás
        </Button>
        <div className="mb-2 underline-link" onClick={onSubmit}>
          Saltar paso
        </div>
        <Button
          variant="link"
          className="btn-theme mb-2"
          onClick={addBeneficiaries}
          disabled={false}
        >
          Agregar personas
        </Button>
      </div>
    </>
  );
};

export default Step3;
