import { gql } from '@apollo/client';

// Page starts with 1
const MULTIPLE_SEARCH = gql`
  query MULTIPLE_SEARCH($email: String!, $name: String!, $dni: String!) {
    customerFor(type: "email", data: $email) {
      id
      first_name
      last_name
      customer_type
      email
      dni
      mobile
      status
      created_at
      subscription {
        amount
        id
        created
        plan {
          id
          name
          months
          price
          tax_id
        }
      }
      beneficiaries {
        id
        first_name
        last_name
        customer_type
        email
        dni
        mobile
        status
        subscription {
          id
          created
          plan {
            id
            name
            months
            price
            tax_id
          }
        }
      }
      payments {
        date
      }
    }
    customerFor(type: "name", data: $name) {
      id
      first_name
      last_name
      customer_type
      email
      dni
      mobile
      status
      created_at
      subscription {
        amount
        id
        created
        plan {
          id
          name
          months
          price
          tax_id
        }
      }
      beneficiaries {
        id
        first_name
        last_name
        customer_type
        email
        dni
        mobile
        status
        subscription {
          id
          created
          plan {
            id
            name
            months
            price
            tax_id
          }
        }
      }
      payments {
        date
      }
    }
    customerFor(type: "dni", data: $dni) {
      id
      first_name
      last_name
      customer_type
      email
      dni
      mobile
      status
      created_at
      subscription {
        amount
        id
        created
        plan {
          id
          name
          months
          price
          tax_id
        }
      }
      beneficiaries {
        id
        first_name
        last_name
        customer_type
        email
        dni
        mobile
        status
        subscription {
          id
          created
          plan {
            id
            name
            months
            price
            tax_id
          }
        }
      }
      payments {
        date
      }
    }
  }
`;
export default MULTIPLE_SEARCH;
