import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import CountryWithCode from '../../../../Common/CountryWithCode.json';
import { register as registerAction } from '../../../../state/actions/register';
import schema from './schema';
import { Mutations } from '../../../../Queries';
import Loader from '../../../../Common/Loader/Loader';
import TagManager from 'react-gtm-module';
import { gtmNames } from '../../../../constants';

const Step2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [updateRegister, { loading }] = useMutation(Mutations.REGISTER, {
    fetchPolicy: 'no-cache',
  });
  const [queryError, setQueryError] = useState('');
  const registerData = useSelector((state) => state.register);

  const data = CountryWithCode.filter(
    (county) => county.name.toLowerCase() === registerData.country.toLowerCase()
  )[0];
  let temp = '+506';
  if (data?.name) {
    temp = `${data.code} ${data.dial_code}`;
  }

  useEffect(() => {
    dispatch(registerAction({ currentStep: 1 }));
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <Formik
        initialValues={{
          email: registerData.email,
          contact: registerData.mobile,
        }}
        validationSchema={schema}
        validateOnChange
        // change 'redux state' from above when submitting
        onSubmit={async ({ email, contact }) => {
          try {
            await updateRegister({
              variables: {
                dni: registerData.dni,
                first_name: registerData.firstName,
                last_name: registerData.lastName,
                dni_type: registerData.dniType,
                email,
                mobile: contact,
              },
            });
            TagManager.dataLayer({
              dataLayer: {
                event: gtmNames.titularAddInfo,
              },
            });
          } catch (error) {
            setQueryError(error.message);
            return;
          }
          sessionStorage.setItem('email', email);
          dispatch(registerAction({ mobile: contact, email }));
          history.push('/plan/3');
        }}
      >
        {(formik) => (
          <>
            <Form className="resgistration-form center-form">
              <div className="form-header">
                <h2 className="form-title">
                  <span>Información de Contacto</span>
                </h2>
              </div>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="ejemplo@ejemplo.com"
                  name="email"
                  className={formik.errors.email ? 'is-invalid' : ''}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="error-text">{formik.errors.email}</span>
                )}
              </Form.Group>
              <Form.Group>
                <div className="input-group-custom">
                  <div className="input-group-code">
                    <Form.Label>Código país</Form.Label>
                    <div className="append-custom-input">{temp}</div>
                  </div>
                  <div className="input-group-country">
                    <Form.Label>Teléfono</Form.Label>
                    <Form.Control
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      type="text"
                      placeholder="0000 0000"
                      name="contact"
                      className={formik.errors.contact ? 'is-invalid' : ''}
                      value={formik.values.contact}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                {formik.touched.contact && formik.errors.contact && (
                  <span className="error-text">{formik.errors.contact}</span>
                )}
              </Form.Group>
              <Form.Group>
                {queryError && <span className="error-text">{queryError}</span>}
              </Form.Group>
            </Form>
            <div className="d-flex align-items-center justify-content-between flex-wrap btn-wrap">
              <Button
                variant="link"
                className="btn-theme-outline mb-2"
                onClick={() => {
                  history.push('/plan/1');
                }}
                disabled={false}
              >
                Atrás
              </Button>
              <Button
                variant="link"
                className="btn-theme mb-2"
                onClick={formik.handleSubmit}
                disabled={!formik.isValid}
              >
                Continuar
              </Button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default Step2;
