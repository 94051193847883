import { gql } from '@apollo/client';

const NOTIFICATIONS_CONFIG = gql`
  query {
    notificationsConfigInfo {
      subscription_update
      beneficiary_update
    }
  }
`;
export default NOTIFICATIONS_CONFIG;
