import { gql } from '@apollo/client';

const ME = gql`
  query {
    me {
      dni_type
      id
      first_name
      last_name
      customer_type
      email
      dni
      mobile
      status
      card_number
      maturity_date
      holder_name
      subscription {
        id
        created
        amount
        status
        trial_days 
        coupon {
          id
          name
          description
          discount_value
          percentage_value
          apply_to_addons
          apply_to_plans
          beneficiary
          coupon_code
          max_redemption
          plans {
            id
            plan_type
            name
          }
          addons {
            id
            type
          }
        }
        plan {
          id
          name
          months
          price
          iva
          tax_id
          trial_period
          beneficiary_cost {
            iva
            name
            price
            total_price
            id
            description
          }
        }
        addons {
          id
          name
          description
          plans {
            id
            name
            months
            price
            tax_id
          }
          price
          iva
        }
      }
      beneficiaries {
        id
        first_name
        last_name
        customer_type
        email
        dni
        mobile
        status
        created_at
        subscription {
          id
          created
          plan {
            id
            name
            months
            price
            tax_id
          }
          addons {
            id
            name
            description
            plans {
              id
              name
              months
              price
              tax_id
            }
            price
          }
        }
        payments {
          date
        }
      }
      payments {
        date
        card_type
        plan
        card_masked
        status
        amount
      }
    }
  }
`;
export default ME;
